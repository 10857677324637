export default function Resume() {
  return (
    <></>
    // <PDFViewer

    //   document={{
    //     url: 'https://arxiv.org/pdf/quant-ph/0410100.pdf',
    //   }}
    // />
  );
}
